var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewItem}},[_vm._v(" New Item ")])]),_c('CDataTable',{ref:"vuetable",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":20,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"fileId",fn:function({ item }){return [(item.fileId)?_c('td',[_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"src":_vm.getImageUrl(item.fileId)}})]):_c('td',[_vm._v("--")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'Checks',
                  params: {
                    itemId: item.itemId,
                    taskId: item.taskId,
                    mode: 'view',
                  },
                }}},[_vm._v("Checks")]),_c('CDropdownItem',{attrs:{"to":{
                  name: 'Item',
                  params: { itemId: item.itemId, mode: 'view' },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteItem(item.itemId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"}),_c('CCardFooter',{attrs:{"align":"right"}},[_c('CButton',{staticClass:"mr-2",attrs:{"type":"Cancel","size":"sm","color":"danger"},on:{"click":function($event){return _vm.cancel()}}},[_c('CIcon',{attrs:{"name":"cil-ban"}}),_vm._v(" Cancel ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }