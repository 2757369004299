<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewItem"
          >
            New Item
          </button>
        </div>

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>
          <template #fileId="{ item }">
            <td v-if="item.fileId">
              <img
                :src="getImageUrl(item.fileId)"
                style="max-width: 100px; max-height: 100px"
              />
            </td>
            <td v-else>--</td>
          </template>
          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'Checks',
                    params: {
                      itemId: item.itemId,
                      taskId: item.taskId,
                      mode: 'view',
                    },
                  }"
                  >Checks</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'Item',
                    params: { itemId: item.itemId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >
                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteItem(item.itemId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  { key: "itemName", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "fileId",
    label: "Image",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Items",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getItems() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/items/" +
          this.$route.params.taskId
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },

    deleteItem(itemId) {
      console.log("delete called with", this.itemId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/delete/item/" + itemId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getItems();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },

    getImageUrl(fileId) {
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },

    navToNewItem() {
      this.$router.push({
        name: "Item",
        params: { taskId: this.$route.params.taskId, mode: "new" },
      });
    },
    cancel() {
      //  this.$router.push({ name: 'Sub Groups' })
      this.$router.push({
        name: "Tasks",
        params: {
          checklistId: this.$route.params.checklistId,
          modelId: this.$route.params.modelId,
          subGroupId: this.$route.params.subGroupId,
          groupId: this.$route.params.groupId,
        },
      });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>